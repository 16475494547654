import { config } from '@/config';
import { WORDPRESS_MUTATION, WORDPRESS_QUERY } from './enums';

function getGraphQLQueryEndpointInfo(
  query: WORDPRESS_QUERY,
  variables?: Record<string, any>,
): string | null {
  switch (query) {
    case WORDPRESS_QUERY.GET_EMPLOYEE_SNACKBAR:
      return '/api/employeeSnackbar';
    case WORDPRESS_QUERY.GET_EMPOYEE_CUPON_LEFT_USES:
      return `/api/employeeSnackbar?userId=${variables?.userId}&coupon=${variables?.coupon}`;
    case WORDPRESS_QUERY.OFFENSIVE_WORDS:
      return '/api/offensiveWords';
    case WORDPRESS_QUERY.INCIDENCE_REASONS:
      return '/api/incidenceReasons';
    case WORDPRESS_QUERY.EXPERIENCIES:
      return '/api/experiencies';
    case WORDPRESS_QUERY.PAYMENT_METHODS:
      return '/api/paymentMethod';
    case WORDPRESS_QUERY.ORDER_RESUME:
      return `/api/orderResume?orderId=${variables?.orderId}`;
    case WORDPRESS_QUERY.SUBSCRIPTIONS:
      return '/api/subscriptions';
    case WORDPRESS_QUERY.GET_CART:
      return '/api/cart';
    case WORDPRESS_QUERY.GET_CUSTOMER_DIRECTIONS:
      return '/api/customerDirection';
    case WORDPRESS_QUERY.PAYMENT_FORM:
      return `/api/paymentForm?orderId=${variables?.orderId}&method=${variables?.method}`;
    case WORDPRESS_QUERY.GET_GUEST_ORDER:
      return `/api/guestOrder?orderID=${variables?.orderID}`;
    case WORDPRESS_QUERY.TRIBU:
      return '/api/tribu';
    case WORDPRESS_QUERY.ORDER:
      return `/api/order?orderId=${variables?.orderId}`;
    case WORDPRESS_QUERY.PREVIEW_ORDERS:
      const queryParams = [];

      if (variables?.customer) {
        queryParams.push(`customer=${variables.customer}`);
      }

      if (variables?.first) {
        queryParams.push(`first=${variables.first}`);
      }

      if (variables?.after) {
        queryParams.push(`after=${variables.after}`);
      }

      if (variables?.status) {
        queryParams.push(`status=${variables.status}`);
      }

      if (variables?.statuses && variables.statuses.length > 0) {
        queryParams.push(`statuses=${variables.statuses}`);
      }

      if (queryParams.length === 0) {
        return '/api/previewOrders';
      }

      if (queryParams.length === 1) {
        return `/api/previewOrders?${queryParams[0]}`;
      }

      return `/api/previewOrders?${queryParams[0]}&${queryParams?.slice(1).join('&')}`;

    case WORDPRESS_QUERY.USER_VOTES:
      return '/api/userVotes';
    case WORDPRESS_QUERY.USER_DIRECTIONS:
      return '/api/userDirections';
    case WORDPRESS_QUERY.CUSTOMER_TYPE:
      return '/api/customerType';
    default:
      return null;
  }
}

function getGraphQLMutationEndpointInfo(
  mutation: WORDPRESS_MUTATION,
  variables?: Record<string, any>,
): {
  url: string;
  body: { action?: WORDPRESS_MUTATION; payload?: Record<string, any> };
} | null {
  switch (mutation) {
    case WORDPRESS_MUTATION.ADD_TO_CART:
    case WORDPRESS_MUTATION.UPDATE_QUANTITY:
    case WORDPRESS_MUTATION.APPLY_COUPON:
    case WORDPRESS_MUTATION.REMOVE_COUPON:
    case WORDPRESS_MUTATION.UPDATE_SUBSCRIPTION:
    case WORDPRESS_MUTATION.CLEAN_CART:
    case WORDPRESS_MUTATION.REPEAT_ORDER:
      return {
        url: '/api/cart',
        body: { action: mutation, payload: variables },
      };
    case WORDPRESS_MUTATION.VOTE_SELLER:
      return {
        url: '/api/voteSeller',
        body: { payload: variables },
      };
    case WORDPRESS_MUTATION.CHECK_ORDER_AS_RECIVED:
      return {
        url: '/api/orderRecived',
        body: { payload: variables },
      };
    case WORDPRESS_MUTATION.ADD_PAYMENT_METHOD:
    case WORDPRESS_MUTATION.DELETE_PAYMENT_METHOD:
      return {
        url: '/api/paymentMethod',
        body: { action: mutation, payload: variables },
      };
    case WORDPRESS_MUTATION.DELETE_CUSTOMER_DIRECTION:
    case WORDPRESS_MUTATION.UPDATE_CUSTOMER_DIRECTION:
    case WORDPRESS_MUTATION.UPDATE_CUSTOMER_DEFAULT_DIRECTION:
      return {
        url: '/api/customerDirection',
        body: { action: mutation, payload: variables },
      };
    case WORDPRESS_MUTATION.VERIFY_PRODUCT_ALERT:
      return {
        url: '/api/stockAlert',
        body: { payload: variables },
      };
    case WORDPRESS_MUTATION.CHECKOUT:
      return { url: '/api/checkout', body: { payload: variables } };
    case WORDPRESS_MUTATION.MIRAKL_CANCEL_ORDER:
      return { url: '/api/cancelOrder', body: { payload: variables } };
    case WORDPRESS_MUTATION.OPEN_INCIDENCE:
      return { url: '/api/openIncidence', body: { payload: variables } };
    case WORDPRESS_MUTATION.UPDATE_CUSTOMER_INFO:
      return { url: '/api/customerInfo', body: { payload: variables } };
    case WORDPRESS_MUTATION.SEND_ERROR_LOG:
      return { url: '/api/errorLog', body: { payload: variables } };
    default:
      return null;
  }
}

type QueryResult = {
  data?: Record<string, any>;
  errors?: {
    message: string;
    extensions: Record<string, any>;
    locations: { line: number; column: number }[];
  }[];
  extensions: {
    debug: {
      type: string;
      message: string;
    }[];
  };
};

type GraphQLQueryOptions<TVariables> = {
  query: WORDPRESS_QUERY;
  variables?: TVariables;
  signal?: AbortSignal;
};

type GraphQLMutationOptions<TVariables> = {
  mutation: WORDPRESS_MUTATION;
  variables?: TVariables;
  signal?: AbortSignal;
};

type GraphQLClientResult<TData> = {
  data: TData;
  error?: string;
  errors?: QueryResult['errors'];
};

type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

/**
 * Instance of `GraphQLClient` to execute queries and mutations on the api endpoints
 * without leaking the query to the user.
 */
export class GraphQLClient {
  currentRequests;

  constructor() {
    this.currentRequests = new Map();
  }

  async _request(
    url: string,
    method: HttpMethod,
    body?: Record<string, any> | null,
    signal?: AbortSignal | null,
  ): Promise<any> {
    const woocommerceSession = localStorage.getItem(config.session.woocommerce);
    const headers = new Headers();

    if (woocommerceSession) {
      headers.append('woocommerce-session', `Session ${woocommerceSession}`);
    }

    const promiseKey = JSON.stringify({ url, method, body });

    const promise =
      method === 'GET' && this.currentRequests.has(promiseKey)
        ? Promise.resolve(this.currentRequests.get(promiseKey))
        : fetch(url, {
            method,
            headers,
            ...(body && { body: JSON.stringify(body) }),
            ...(signal && { signal }),
          })
            .then((response) => {
              const woocommerceSession = response.headers.get(
                'woocommerce-session',
              );

              // We save the woocommerce session in the local storage for future operations.
              if (
                woocommerceSession &&
                localStorage.getItem(config.session.woocommerce) !==
                  woocommerceSession
              ) {
                localStorage.setItem(
                  config.session.woocommerce,
                  woocommerceSession,
                );
              }

              return response.json();
            })
            .then(
              (value: {
                data: QueryResult['data'] | null;
                errors: QueryResult['errors'] | null;
              }) => {
                this.currentRequests.delete(promiseKey);

                if (value?.errors && value.errors.length > 0) {
                  return {
                    data: null,
                    error: value.errors[0]!.message,
                    errors: value.errors,
                  } satisfies GraphQLClientResult<any>;
                }

                return { data: value.data } satisfies GraphQLClientResult<any>;
              },
            )
            .catch((reason) => {
              this.currentRequests.delete(promiseKey);

              return { data: null, error: reason.message };
            });

    if (method === 'GET') {
      this.currentRequests.set(promiseKey, promise);
    }

    return promise;
  }

  // TODO: Improve error handle.
  async query<TData = any, TVariables extends object = any>(
    options: GraphQLQueryOptions<TVariables>,
  ): Promise<GraphQLClientResult<TData>> {
    const queryInfo = getGraphQLQueryEndpointInfo(
      options.query,
      options?.variables,
    )!;

    return this._request(queryInfo, 'GET', null, options?.signal);
  }

  // TODO: Improve error handle.
  async mutate<TData extends object = any, TVariables extends object = any>(
    options: GraphQLMutationOptions<TVariables>,
  ): Promise<GraphQLClientResult<TData>> {
    const mutationInfo = getGraphQLMutationEndpointInfo(
      options?.mutation,
      options?.variables,
    )!;

    return this._request(
      mutationInfo?.url,
      'POST',
      mutationInfo?.body,
      options?.signal,
    );
  }
}

const GQLClient = new GraphQLClient();

export default GQLClient;
