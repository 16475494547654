import { createContext, useReducer } from 'react';
import { useRouter } from 'next/router';

import { WORDPRESS_MUTATION, WORDPRESS_QUERY } from '@/lib/graphql/enums';
import APClient from '@/lib/graphql/utils';
import { useMutation, useToastDispatch } from '@/lib/hooks';
import { routes } from '@/lib/routes';
import {
  debounceAsync,
  getAddedOrUpdatedItemFromCart,
  gtmPush,
  onlyInLeft,
  priceToNumber,
  toCurrency,
} from '@/lib/utils';
import { cleanGTMItemData, getLocationName } from '@/lib/utils/gtm/helpers';
import { normalizeProduct, normalizeProducts } from '@/lib/utils/gtm/products';
import { DEFAULT_TOAST_OPTIONS_WARNING } from './ToastProvider';

import type { TypeCart } from '@/lib/graphql/types';
import type { ReactNode } from 'react';

const enum CartAction {
  UPDATE_CART_CONTENT,
  UPDATE_CART_LOADING,
  UPDATE_CART_ERRORS,
}

type TypeCartAction =
  | {
      type: CartAction.UPDATE_CART_CONTENT;
      payload: {
        cart: TypeCart['cart'];
      };
    }
  | {
      type: CartAction.UPDATE_CART_LOADING;
      payload: {
        loading: boolean;
      };
    };

export type TypeCartDispatch = {
  getCart: () => Promise<any>;
  add: ({
    productId,
    quantity,
    extraData,
    gtm,
  }: {
    productId: number;
    quantity: number;
    extraData?: { [key: string]: any }[];
    gtm?: GTM;
  }) => Promise<any>;
  update: ({
    keyCartProduct,
    quantity,
    gtm,
  }: {
    keyCartProduct: string | number;
    quantity: number;
    gtm?: GTM;
  }) => Promise<any>;
  remove: ({
    keyCartProduct,
    gtm,
  }: {
    keyCartProduct: string;
    gtm: GTM;
  }) => Promise<any>;
  applyCoupon: (code: string) => Promise<any>;
  removeCoupon: (code: string) => Promise<any>;
  updateSubscriptionSchema: (schema: string) => Promise<any>;
  repeatOrder: (
    products: { quantity: number; productId: number }[],
  ) => Promise<any>;
  cleanCart: () => Promise<any>;
};

type TypeProductsToAdd = Map<
  string | number,
  {
    productId: string | number;
    quantity: number;
    extraData?: string;
  }
>;

interface GTM {
  index?: number;
  listName?: string;
  location?:
    | 'header_bag'
    | 'header search'
    | ReturnType<typeof getLocationName>;
}

type TypeGTMProductsToAdd = Map<string | number, GTM>;

const initialState: TypeCart = {
  cart: {
    appliedCoupons: [],
    availablePaymentMethods: [],
    availableShippingMethods: [],
    chosenShippingMethods: [],
    contents: {
      cart: [],
      edges: [],
      isMixedCart: false,
      itemCount: 0,
      products: [],
      typeOfPurchase: {
        discountCart: 0,
        isOnlySuscribable: false,
        isSubscription: false,
        onlySuscriptionPrice: 0,
        subscriptionPrice: 0,
        total: 0,
        totalDiscount: 0,
        uniquePrice: 0,
        withoutSubscription: 0,
      },
    },
    contentsTax: '0,000€',
    contentsTotal: '0,000€',
    discountTax: '0,000€',
    discountTotal: '0,000€',
    displayPricesIncludeTax: true,
    errors: undefined,
    feeTax: '0,000€',
    feeTotal: '0,000€',
    shippingDates: [],
    shippingTax: '0,000€',
    shippingTotal: '0,000€',
    subscriptionData: {
      discount: 0,
      discount_cart: 0,
      hasSuscription: '',
      recurring_purchase: 0,
      single_purchase: 0,
      subscription: 0,
      total: 0,
      total_discount: 0,
    },
    subtotal: '0,000€',
    subtotalTax: '0,000€',
    total: '0,000€',
    totalTax: '0,000€',
    warnings: undefined,
  },
  cartError: { message: '' },
  loading: true,
};

export const CartContext = createContext<TypeCart>(initialState);
export const CartDispatchContext = createContext<TypeCartDispatch>({
  add: async () => null,
  update: async () => null,
  remove: async () => null,
  applyCoupon: async () => null,
  removeCoupon: async () => null,
  updateSubscriptionSchema: async () => null,
  repeatOrder: async () => null,
  cleanCart: async () => null,
  getCart: async () => null,
});

const reducer = (cart: TypeCart, action: TypeCartAction): TypeCart => {
  switch (action.type) {
    case CartAction.UPDATE_CART_CONTENT:
      return {
        ...cart,
        cart: action.payload.cart,
      };
    case CartAction.UPDATE_CART_LOADING:
      return {
        ...cart,
        loading: action.payload.loading,
      };
    default: {
      throw Error(`Tipo de action desconocida: ${action}`);
    }
  }
};

const productsToAdd: TypeProductsToAdd = new Map();
const gtmProductsToAdd: TypeGTMProductsToAdd = new Map();

const promiseStack: {
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
  payload: TypeProductsToAdd;
  gtm?: TypeGTMProductsToAdd;
}[] = [];

export const CartProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { toast } = useToastDispatch();
  const router = useRouter();

  const [addToCart, { loading }] = useMutation(WORDPRESS_MUTATION.ADD_TO_CART);

  const addToCartRecursive = () => {
    const { resolve, reject, payload, gtm } = promiseStack.shift()!; // TS fails to understand guard for Array.prototype.shift

    addToCart({
      variables: {
        items: Array.from(payload.values()),
      },
    })
      .then((value) => {
        if (value?.error) {
          toast(value.error, DEFAULT_TOAST_OPTIONS_WARNING);
          reject(value.error);
          return;
        }

        const cart: TypeCart['cart'] = value.data.addCartItems.cart;

        if (cart?.errors || cart?.warnings) {
          toast((cart.errors || cart.warnings) ?? '', {
            ...DEFAULT_TOAST_OPTIONS_WARNING,
            ...((cart.errors || cart.warnings) ===
              'No puedes añadir este producto porque superarías el límite de peso.' && {
              link: {
                type: 'url',
                label: 'Leer más',
                url: '/bases-legales#entregas',
              },
            }),
          });
          reject(cart.errors || cart.warnings);
        }

        const oldItems =
          state.cart.contents.products?.map((item) => ({
            key: item.key,
            quantity: item.quantity,
            id: item.product.databaseId,
          })) ?? [];
        const newItems =
          cart.contents.products?.map((item) => ({
            key: item.key,
            quantity: item.quantity,
            id: item.product.databaseId,
          })) ?? [];

        const diff = onlyInLeft(
          newItems,
          oldItems,
          (a, b) => a.key === b.key && a.quantity === b.quantity,
        );

        const diffWithQuantity = diff.map((item) => ({
          ...item,
          quantity: payload.get(item.id)?.quantity,
        }));

        dispatch({
          type: CartAction.UPDATE_CART_CONTENT,
          payload: {
            cart: cart,
          },
        });

        for (const item of diffWithQuantity) {
          const addedItem = getAddedOrUpdatedItemFromCart(item.key, cart);
          const data = gtm?.get(item.id);

          if (addedItem.data) {
            const quantity = item.quantity ?? addedItem.data.quantity;
            // The field total bring the real price of the product that your are paying (with discount, etc)
            // so we need to calculate the price of the product with the quantity that you are adding
            // To sum up: total / total quantity * quantity that we are adding
            const total = toCurrency(
              (priceToNumber(addedItem.data.total) /
                (diff.at(0)?.quantity || 1)) *
                quantity,
            );

            gtmPush({
              event: 'add_to_cart',
              ecommerce: {
                items: [
                  normalizeProduct({
                    offer: {
                      ...addedItem.data,
                      total,
                      quantity,
                    },
                    itemListName: data?.listName,
                    index: data?.index,
                  }),
                ],
                currency: 'EUR',
                value:
                  priceToNumber(addedItem.data.product.price) *
                  (item.quantity || 1),
                location: data?.location || getLocationName(),
              },
              connectif: normalizeProducts({ cart }),
            });

            cleanGTMItemData();
          }
        }

        resolve(cart);

        if (promiseStack.length > 0) {
          addToCartRecursive();
        }
      })
      .catch((error) => {
        getCart();
        toast(error.message, {
          ...DEFAULT_TOAST_OPTIONS_WARNING,
          ...(error.message ===
            'No puedes añadir este producto porque superarías el límite de peso.' && {
            link: {
              type: 'url',
              label: 'Leer más',
              url: '/bases-legales#entregas',
            },
          }),
        });
        reject(error);
      });
  };

  const addToCartDebounce = debounceAsync(
    () =>
      new Promise((resolve, reject) => {
        promiseStack.push({
          resolve,
          reject,
          payload: new Map(productsToAdd),
          gtm: new Map(gtmProductsToAdd),
        });
        productsToAdd.clear();
        if (!loading) {
          addToCartRecursive();
        }
      }),
    800,
  );

  const add = async ({
    productId,
    quantity,
    extraData,
    gtm,
  }: {
    productId: number;
    quantity: number;
    extraData?: { [key: string]: any }[];
    gtm?: GTM;
  }) => {
    const extraDataWithGTM = extraData ?? [];

    const product = state.cart.contents.products.find(
      (product) => product.product.databaseId === productId,
    );
    const itemListName = product?.extraData.find(
      (data) => data.key === 'cart_analytics_item_list_name',
    )?.value;
    const itemIndex = product?.extraData.find(
      (data) => data.key === 'cart_analytics_index',
    )?.value;

    extraDataWithGTM.push({
      cart_analytics_item_list_name:
        itemListName ||
        (window?.itemListName || gtm?.listName)?.split(' ').join('_') ||
        'direct',
      cart_analytics_index: itemIndex || window?.itemIndex || gtm?.index,
    });

    productsToAdd.set(productId, {
      productId,
      quantity,
      extraData: JSON.stringify(extraDataWithGTM),
    });

    if (gtm) gtmProductsToAdd.set(productId, gtm);

    // TODO: Contemplar posibilidad de devolver solo el numero de productos en el carrito y
    // mover el getCart al hover en el Toolbar, actualmente la query de getCart es demaisado
    // lenta para esto, investigar cachear la query -> https://www.apollographql.com/docs/react/data/queries#caching-query-results

    // TODO: Si un producto del array hace fallar la mutacion, todos los productos anteriores
    // a este si que se añaden al carrito, el problema reside en que la mutacion al fallar
    // no devuelve el carrito con los productos que haya podido añadir si no que devuelve un carrito nulo,
    // esto dificulta que en front podamos controlar si el carrito se ha llenado aunque sea solo hasta cierto punto o esta vacio.
    //
    // Como solucion temporal a este problema, cada vez que la mutacion de añadir al carrito falle, vamos a lanzar la query getCart()
    // para obtener el carrito y poder mostrarlo con los productos añadidos antes del fallo.

    return addToCartDebounce();
  };

  const update = async ({
    keyCartProduct,
    quantity,
    gtm,
  }: {
    keyCartProduct: string | number;
    quantity: number;
    gtm?: GTM;
  }) =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: WORDPRESS_MUTATION.UPDATE_QUANTITY,
        variables: {
          key: keyCartProduct,
          quantity: quantity,
        },
      })
        .then((value) => {
          const newCart: TypeCart['cart'] =
            value.data.updateItemQuantities.cart;

          if (newCart?.errors || newCart?.warnings) {
            toast((newCart?.errors ?? '') || (newCart?.warnings ?? ''), {
              ...DEFAULT_TOAST_OPTIONS_WARNING,
              ...(((newCart?.errors ?? '') || (newCart?.warnings ?? '')) ===
                'No puedes añadir este producto porque superarías el límite de peso.' && {
                link: {
                  type: 'url',
                  label: 'Leer más',
                  url: '/bases-legales#entregas',
                },
              }),
            });
            reject(newCart.errors || newCart.warnings);
          }

          const oldCart: TypeCart['cart'] = structuredClone(state.cart);

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: newCart,
            },
          });

          const updatedItem = getAddedOrUpdatedItemFromCart(
            keyCartProduct.toString(),
            quantity !== 0 ? newCart : oldCart,
          );

          if (updatedItem.data) {
            const updatedQuantity =
              Math.abs(updatedItem.data.quantity - quantity) || 1;
            // The field total bring the real price of the product that your are paying (with discount, etc)
            // so we need to calculate the price of the product with the quantity that you are updating
            // To sum up: total / total quantity * quantity that we are updating
            const total = toCurrency(
              (priceToNumber(updatedItem.data.total) /
                (updatedItem.data.quantity || 1)) *
                updatedQuantity,
            );

            gtmPush({
              event:
                quantity === 0
                  ? 'remove_from_cart'
                  : (oldCart?.contents.itemCount ?? 0) <
                      (newCart?.contents.itemCount ?? 0)
                    ? 'add_to_cart'
                    : 'remove_from_cart',
              ecommerce: {
                items: [
                  normalizeProduct({
                    offer: {
                      ...updatedItem.data,
                      total,
                      quantity: updatedQuantity,
                    },
                    itemListName: gtm?.listName,
                    index: gtm?.index,
                  }),
                ],
                currency: 'EUR',
                value:
                  priceToNumber(updatedItem.data.product.price) *
                  updatedQuantity,
                location: gtm?.location || getLocationName(),
              },
              connectif: normalizeProducts({ cart: newCart }),
            });

            cleanGTMItemData();
          }

          resolve(newCart);
        })
        .catch((error) => {
          toast(error.message, {
            ...DEFAULT_TOAST_OPTIONS_WARNING,
            ...(error.message ===
              'No puedes añadir este producto porque superarías el límite de peso.' && {
              link: {
                type: 'url',
                label: 'Leer más',
                url: '/bases-legales#entregas',
              },
            }),
          });
          reject(error);
        });
    });

  const remove = async ({
    keyCartProduct,
    gtm,
  }: {
    keyCartProduct: string | number;
    gtm?: GTM;
  }) => {
    await update({ keyCartProduct, quantity: 0, gtm });
  };

  const applyCoupon = async (code: string) =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: WORDPRESS_MUTATION.APPLY_COUPON,
        variables: {
          code: code,
        },
      })
        .then((value) => {
          if (value.error) {
            toast(value.error, DEFAULT_TOAST_OPTIONS_WARNING);
            reject(value.error);
            return;
          }

          const cart: TypeCart['cart'] = value.data.applyCoupon.cart;

          if (cart?.errors || cart?.warnings) {
            toast(
              (cart.errors || cart.warnings) ?? '',
              DEFAULT_TOAST_OPTIONS_WARNING,
            );
            reject(cart.errors || cart.warnings);
          }

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: cart,
            },
          });

          resolve(cart);
        })
        .catch((error) => {
          toast(error.message, DEFAULT_TOAST_OPTIONS_WARNING);
          reject(error);
        });
    });

  const removeCoupon = async (code: string) =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: WORDPRESS_MUTATION.REMOVE_COUPON,
        variables: {
          code: code,
        },
      })
        .then((value) => {
          if (value?.error) {
            toast(value.error, DEFAULT_TOAST_OPTIONS_WARNING);
            reject(value.error);
            return;
          }

          const cart: TypeCart['cart'] = value.data.removeCoupons.cart;

          if (cart?.errors || cart?.warnings) {
            toast(
              (cart.errors || cart.warnings) ?? '',
              DEFAULT_TOAST_OPTIONS_WARNING,
            );
            reject(cart.errors || cart.warnings);
          }

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: cart,
            },
          });

          resolve(cart);
        })
        .catch((error) => {
          toast(error.message, DEFAULT_TOAST_OPTIONS_WARNING);
          reject(error);
        });
    });

  const updateSubscriptionSchema = async (schema: string) =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: WORDPRESS_MUTATION.UPDATE_SUBSCRIPTION,
        variables: {
          schema: schema,
        },
      })
        .then((value) => {
          if (value?.error) {
            toast(value.error, DEFAULT_TOAST_OPTIONS_WARNING);
            reject(value.error);
            return;
          }

          const cart: TypeCart['cart'] =
            value.data.updateSubscriptionSchema.cart;

          if (cart?.errors || cart?.warnings) {
            toast(
              (cart.errors || cart.warnings) ?? '',
              DEFAULT_TOAST_OPTIONS_WARNING,
            );
            reject(cart.errors || cart.warnings);
          }

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: cart,
            },
          });

          resolve(cart);
        })
        .catch((error) => {
          toast(error.message, DEFAULT_TOAST_OPTIONS_WARNING);
          reject(error);
        });
    });

  const cleanCart = async () =>
    new Promise((resolve, reject) => {
      APClient.mutate({
        mutation: WORDPRESS_MUTATION.CLEAN_CART,
      })
        .then((value) => {
          if (value?.error) {
            toast(value.error, DEFAULT_TOAST_OPTIONS_WARNING);
            reject(value.error);
            return;
          }

          const cart: TypeCart['cart'] = value.data.emptyCart.cart;

          if (cart?.errors || cart?.warnings) {
            toast(
              (cart.errors || cart.warnings) ?? '',
              DEFAULT_TOAST_OPTIONS_WARNING,
            );
            reject(cart.errors || cart.warnings);
          }

          dispatch({
            type: CartAction.UPDATE_CART_CONTENT,
            payload: {
              cart: cart,
            },
          });

          resolve(cart);
        })
        .catch((error) => {
          if (error.message === 'Cart is empty') {
            resolve(initialState);
            return;
          }

          toast(error.message, DEFAULT_TOAST_OPTIONS_WARNING);
          reject(error);
        });
    });

  const repeatOrder = async (
    products: { productId: number; quantity: number }[],
  ) =>
    new Promise((resolve, reject) => {
      if (products.length === 0) {
        toast(
          'No se han podido añadir los artículos a la cesta',
          DEFAULT_TOAST_OPTIONS_WARNING,
        );
        reject('No se han podido añadir los artículos a la cesta');
      }

      const repeatOrder = () => {
        APClient.mutate({
          mutation: WORDPRESS_MUTATION.REPEAT_ORDER,
          variables: {
            items: products,
          },
        })
          .then((value) => {
            if (value?.error) {
              toast(value.error, DEFAULT_TOAST_OPTIONS_WARNING);
              reject(value.error);
              return;
            }

            const cart: TypeCart['cart'] = value.data.fillCart.cart;

            if (cart?.errors || cart?.warnings) {
              toast(
                (cart.errors || cart.warnings) ?? '',
                DEFAULT_TOAST_OPTIONS_WARNING,
              );
              reject(cart.errors || cart.warnings);
            }

            dispatch({
              type: CartAction.UPDATE_CART_CONTENT,
              payload: {
                cart: cart,
              },
            });

            router.push(routes.cart);
            resolve(cart);
          })
          .catch((error) => {
            toast(error.message, DEFAULT_TOAST_OPTIONS_WARNING);
            reject(error);
          });
      };

      if (state.cart.contents.itemCount > 0) {
        cleanCart()
          .then(repeatOrder)
          .catch((error) => {
            if (error.message === 'Cart is empty') {
              repeatOrder();
            } else {
              toast(error.message, DEFAULT_TOAST_OPTIONS_WARNING);
              reject(error);
            }
          });
      } else {
        repeatOrder();
      }
    });

  const getCart = async () => {
    dispatch({
      type: CartAction.UPDATE_CART_LOADING,
      payload: { loading: true },
    });

    APClient.query({
      query: WORDPRESS_QUERY.GET_CART,
    })
      .then((value) => {
        const cart: TypeCart['cart'] = value.data.cart;

        dispatch({
          type: CartAction.UPDATE_CART_CONTENT,
          payload: {
            cart: cart,
          },
        });
      })
      .finally(() => {
        dispatch({
          type: CartAction.UPDATE_CART_LOADING,
          payload: { loading: false },
        });
      });
  };

  return (
    <CartContext.Provider value={state}>
      <CartDispatchContext.Provider
        value={{
          add,
          remove,
          update,
          applyCoupon,
          removeCoupon,
          updateSubscriptionSchema,
          repeatOrder,
          cleanCart,
          getCart,
        }}
      >
        {children}
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  );
};
