import { isOperator } from '../orders';
import {
  getDiscountAmount,
  getPercentageDiscount,
  priceToNumber,
  toFixed,
} from '../price';
import { AFFILIATION_NAME_OPERATOR, getProductCategoriesTree } from './helpers';

import type { TypeCart } from '@/lib/graphql/types';

export interface GTMProduct {
  /**
   * The SKU of the product
   */
  item_id: string;
  /**
   * Database ID of the product
   */
  item_id_connectif: number;
  /**
   * The name of the product
   */
  item_name: string;
  /**
   * Hijos de Rivera if the product is from operator or seller if the product is from Mirakl
   */
  affiliation: string;
  /**
   * Position of the product in the list
   */
  index?: number;
  /**
   * The brand of the product
   */
  item_brand?: string;
  /**
   * The price of the product
   */
  price: number;
  /**
   * Indicate if the product is free
   */
  free_item: 'si' | 'no';
  /**
   * The price of the product if it's free
   */
  free_item_price: number;
  /**
   * The original price of the product
   */
  original_price: number;
  /**
   * The discount of the product
   */
  discount?: number;
  /**
   * The percentage of the discount like "10%"
   */
  discount_percentage?: string;
  /**
   * The quantity of the product
   */
  quantity: number;
  /**
   * Primary category of the product
   */
  item_category?: string;
  /**
   * Secondary category of the product
   */
  item_category2?: string;
  /**
   * Tertiary category of the product
   */
  item_category3?: string;
  /**
   * Quaternary category of the product
   */
  item_category4?: string;
  /**
   * Quinary category of the product
   */
  item_category5?: string;
  /**
   * Variant of the product like "red | xs"
   */
  item_variant?: string;
  /**
   * Items inside the bundle like "SKU-SKU-SKU"
   */
  items_pack?: string;
  /**
   * Indicate the list from which the article comes when performing the e-commerce action. For example, where you saw a product, where you clicked on it, etc.
   *
   * Equivalence Table:
   *  - On the home page = home_<module_name>
   *  - In suggested products = related_products
   *  - In direct traffic = direct
   *  - In searches = search
   *  - In a grid category = <module_name>
   *  - In a product = product
   *  - In the cart = cart
   *  - In the mini cart = mini_cart
   *  - Default = path name of the page
   */
  item_list_name:
    | 'related_products'
    | 'direct'
    | 'search'
    | 'blog'
    | 'product'
    | 'cart'
    | string;
  /**
   * The same value of item_list_name, google require this field
   */
  item_list_id:
    | 'related_products'
    | 'direct'
    | 'search'
    | 'blog'
    | 'product'
    | 'cart'
    | string;
  /**
   * Indicate if the product is a custom box and the variant of the custom box
   *
   * @example
   * item_custom_box: "si_3" | "si_6" | "no"
   */
  item_custom_box?: string;
  /**
   * Image of the product
   */
  item_image?: string;
  /**
   * The URL of the product
   */
  item_url: string;
}

export const normalizeProduct = ({
  offer,
  index,
  itemListName,
}: {
  offer: TypeCart['cart']['contents']['cart'][number]['orders'][number]['offers'][number];
  index?: number;
  itemListName?: GTMProduct['item_list_name'];
}): GTMProduct => {
  const { product, variation } = offer;
  const categories = getProductCategoriesTree(product);
  const price = priceToNumber(offer.total) / offer.quantity;
  let regularPrice = priceToNumber(product.regularPrice);
  let seller: string = AFFILIATION_NAME_OPERATOR;
  let discountAmount: undefined | number;
  let discountPercentage: undefined | number;

  if (variation) {
    regularPrice = priceToNumber(variation.node.regularPrice);

    if (!isOperator(seller)) seller = variation.node.sellerInfo?.name;
  }

  if (price !== regularPrice) {
    discountAmount = getDiscountAmount(regularPrice, price);
    discountPercentage = getPercentageDiscount(regularPrice, price);
  }

  const bundleItems = offer.bundleItems
    ?.map((item) => item.sku)
    .filter(Boolean)
    .join('-');
  const variant = offer.variation?.attributes
    .filter((attr) => attr.name !== 'seller' && attr.name !== 'offer')
    .map((attr) => attr.value)
    .join(' | ');
  const freeItem = priceToNumber(offer.product.price) === 0;
  const customBox = offer.product.customBoxVariant
    ? `si_${offer.product.customBoxVariant}`
    : 'no';
  const cartListName = offer.extraData.find(
    (metaData) => metaData.key === 'cart_analytics_item_list_name',
  );
  const cartListIndex = offer.extraData.find(
    (metaData) => metaData.key === 'cart_analytics_index',
  );
  const listName = (
    cartListName?.value ||
    window?.itemListName ||
    itemListName ||
    'direct'
  )
    .split(' ')
    .join('_');
  const indexItem = cartListIndex?.value
    ? parseInt(cartListIndex.value)
    : (window?.itemIndex ?? index);

  return {
    item_id: offer.product.sku || offer.product.name,
    item_id_connectif: offer.product.databaseId,
    item_name: offer.product.name,
    affiliation: isOperator(seller) ? AFFILIATION_NAME_OPERATOR : seller,
    index: indexItem,
    item_brand: offer.product.brand || undefined,
    price: toFixed(price),
    free_item: freeItem ? 'si' : 'no',
    free_item_price: freeItem ? toFixed(regularPrice) : 0,
    original_price: toFixed(regularPrice),
    discount: (discountAmount && toFixed(discountAmount)) || undefined,
    discount_percentage: discountPercentage
      ? `${discountPercentage}%`
      : undefined,
    quantity: offer.quantity,
    ...categories,
    item_variant: variant || undefined,
    items_pack: bundleItems || undefined,
    item_list_name: listName,
    item_list_id: listName,
    item_custom_box: customBox,
    item_image: offer.product.image?.sourceUrl || undefined,
    item_url: offer.product.link,
  };
};

export const normalizeProducts = ({
  cart,
  itemListName,
}: {
  cart: TypeCart['cart'];
  itemListName?: GTMProduct['item_list_name'];
}): GTMProduct[] =>
  cart.contents.cart.flatMap((seller) =>
    [...seller.orders].flatMap((order, orderIndex) =>
      order.offers.map((offer, offerIndex) =>
        normalizeProduct({
          offer,
          itemListName,
          index: orderIndex + offerIndex + 2,
        }),
      ),
    ),
  );
